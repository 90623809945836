import React, { Component } from 'react';
import style from './down.module.css';
import img from './img/down.png'

const Down = (

	<div className = {style.down}>
		<span>
			<img src = {img} />
		</span>
	</div>

)


export default Down
