exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".footer_wrapper__3rBYU{\r\n    bottom:0;\r\n\tpadding-top: 20px;\r\n\tpadding-bottom: 20px;\r\n    padding-left: 10px;\r\n\tmax-width: 100%;\r\n\t/*margin: auto;*/\r\n}\r\n\r\n.footer_wrapper__3rBYU p{\r\n\tmargin: 0;\r\n\tfont-size: 12px;\r\n    text-align:left;\r\n    color: #646c7f;\r\n}", ""]);

// exports
exports.locals = {
	"wrapper": "footer_wrapper__3rBYU"
};