exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".file_file__JTEMt{\r\n  display: none;\r\n}\r\n.file_fileOn__CKFW2{\r\n  /*position: absolute;\r\n  top:0;\r\n  left: 0;*/\r\n  width: 100%;\r\n  height: 400px;\r\n  color: #646c7f;\r\n  text-align: center;\r\n}\r\np.file_reminder__v8h5R{\r\n  display: none;\r\n}\r\np.file_reminderOn__1yt0L{\r\n  color: #e73737;\r\n  position: absolute;\r\n  margin-bottom: 0;\r\n  width: 100%;\r\n  top: 5px;\r\n  text-align: center;\r\n}\r\n/*p.reminderOn span{\r\n  font-size: 14px;\r\n}*/\r\n.file_dropzone__1TBcs{\r\n\twidth: 100%;\r\n\theight:80%;\r\n\tcursor: pointer;\r\n\ttext-align: center;\r\n\tbox-sizing:border-box;  \r\n\tpadding: 2rem 2rem;\r\n}\r\n.file_dropzone__1TBcs img{\r\n\twidth: 12vw;\r\n\theight: 12vw;\r\n}\r\np.file_instruction__2SJJG{\r\n\ttext-align: center;\r\n\tmargin: auto;\r\n}\r\np.file_instruction__2SJJG span{\r\n\tfont-size: 14px;\r\n\tcolor: #e73737;\r\n}\r\n.file_reverse__3UO5P{\r\n  border: 0;\r\n  cursor: pointer;\r\n  font-size: 0.75rem;\r\n  height: 2.5rem;\r\n  line-height: 2.5rem;\r\n  padding: 0 1.5rem;\r\n  color: white;\r\n  display: inline-block;\r\n  margin-bottom: 2%;\r\n  background: #548Ddf;\r\n  text-transform: uppercase;\r\n  border-radius: .25rem;\r\n  letter-spacing: .1em;\r\n  -webkit-transition: background .2s;\r\n  transition: background .2s;\r\n}\r\n/*.reverse{\r\n\ttext-decoration: none;\r\n  color: #999;\r\n  background-color: #fff;\r\n  font-size: 15px;\r\n  border: 0;\r\n  cursor: pointer;\r\n  transition: all .2s;\r\n}*/\r\n.file_reverse__3UO5P:hover {\r\n  background: #7aaDf1;\r\n}\r\n/*.reverse:hover{\r\n\tcolor: #0087f7;\r\n\tfont-size: 16px;\r\n}\r\n.reverse:focus{\r\n\toutline: none;\r\n}*/", ""]);

// exports
exports.locals = {
	"file": "file_file__JTEMt",
	"fileOn": "file_fileOn__CKFW2",
	"reminder": "file_reminder__v8h5R",
	"reminderOn": "file_reminderOn__1yt0L",
	"dropzone": "file_dropzone__1TBcs",
	"instruction": "file_instruction__2SJJG",
	"reverse": "file_reverse__3UO5P"
};