exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".down_down__17q0j{\r\n\theight: 65px;\r\n\twidth: 100%;\r\n\tpadding-top: 22px;\r\n\tbackground-color: #fff;\r\n\ttext-align: center;\r\n}\r\n\r\n.down_down__17q0j img{\r\n\theight: 40px;\r\n\twidth: 40px;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"down": "down_down__17q0j"
};