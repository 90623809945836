exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".api_api__340JU{\r\n    width:70vw;\r\n    margin-top:50px;\r\n\tmargin: auto;\r\n    margin-bottom:50px;\r\n}\r\n\r\n.api_api__340JU p{\r\n\tmargin: auto;\r\n    text-align:left;\r\n}\r\n\r\n.api_api__340JU h3{\r\n\tmargin: auto;\r\n    margin-top:20px;\r\n\tpadding:0;\r\n\twidth: 100%;\r\n\ttext-align: center;\r\n\tfont-size: 18px;\r\n\tfont-weight: 400;\r\n}\r\n\r\n\r\n.api_code__1Ww_n{\r\n    height:500px;\r\n    overflow-y: auto;\r\n    margin-bottom:20px;\r\n    margin-top:10px;\r\n    box-shadow: 0 0 2em rgba(0,0,0,0.2);\t\r\n}\r\n\r\n.api_codeHide__XNLvd{\r\n    display:none;\r\n}\r\n\r\n.api_submit__82ytQ{\r\n  border: 0;\r\n  cursor: pointer;\r\n  font-size: 0.75rem;\r\n  height: 2rem;\r\n  line-height: 2rem;\r\n  padding: 0 1.5rem;\r\n  color: white;\r\n  background: #548Ddf;\r\n  border-radius: .25rem;\r\n  letter-spacing: .1em;\r\n  -webkit-transition: background .2s;\r\n  transition: background .2s;\r\n}\r\n.api_submit__82ytQ:hover{\r\n \tbackground: #7aaDf1;\r\n}", ""]);

// exports
exports.locals = {
	"api": "api_api__340JU",
	"code": "api_code__1Ww_n",
	"codeHide": "api_codeHide__XNLvd",
	"submit": "api_submit__82ytQ"
};