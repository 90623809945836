exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".jobsubmitted_file__3lLRE{\r\n  display: none;\r\n}\r\n.jobsubmitted_fileOn__DApCu{\r\n   /*width: 800px;*/\r\n   width: 100%;\r\n   height: 400px;\r\n   color: #646c7f;\r\n  /*text-align: center;*/\r\n}\r\n\r\n\r\np.jobsubmitted_instruction__268tS{\r\n\ttext-align: center;\r\n    padding: 2rem 2rem;\r\n\tmax-width: 100%;\r\n    font-size:1rem;\r\n}\r\np.jobsubmitted_instruction__268tS span{\r\n\tfont-size: 1rem;\r\n\tcolor: #e73737;\r\n}\r\n\r\n.jobsubmitted_gridcontainer__1rZow {\r\n  display: grid;\r\n  grid: 15rem;\r\n  grid-gap: 5px;\r\n  padding: 3em 1em 0.5em 1em;\r\n}\r\n\r\n.jobsubmitted_gridcontainer__1rZow button{\r\n  text-align: center;\r\n  padding: 5px 0;\r\n  font-size: 0.75rem;\r\n}\r\n\r\n.jobsubmitted_reverse__3mI_-{\r\n  border:0;\r\n  cursor: pointer;\r\n  font-size: 0.75rem;\r\n  height: 2.5rem;\r\n  width: 15rem;\r\n  padding:1rem;\r\n  color: white;\r\n  background: #548Ddf;\r\n  margin:auto;\r\n  /*background: #548Ddf;*/\r\n  /*text-transform: uppercase;*/\r\n  border-radius: .25rem;\r\n  letter-spacing: .1em;\r\n  -webkit-transition: background .2s;\r\n  transition: background .2s;\r\n}\r\n/*.reverse{\r\n\ttext-decoration: none;\r\n  color: #999;\r\n  background-color: #fff;\r\n  font-size: 15px;\r\n  border: 0;\r\n  cursor: pointer;\r\n  transition: all .2s;\r\n}*/\r\n.jobsubmitted_reverse__3mI_-:hover {\r\n  background: #7aaDf1;\r\n}\r\n\r\n\r\n\r\n\r\n.jobsubmitted_resubmit__22FQn{\r\n  border:0;\r\n  cursor: pointer;\r\n  font-size: 0.75rem;\r\n  height: 2.5rem;\r\n  width: 15rem;\r\n  padding: 1rem;\r\n  color: white;\r\n  background: #548Ddf;\r\n  /*background: #548Ddf;*/\r\n  /*text-transform: uppercase;*/\r\n  border-radius: .25rem;\r\n  letter-spacing: .1em;\r\n  -webkit-transition: background .2s;\r\n  transition: background .2s;\r\n  margin:auto;\r\n}\r\n.jobsubmitted_resubmit__22FQn:hover{\r\n \tbackground: #7aaDf1;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"file": "jobsubmitted_file__3lLRE",
	"fileOn": "jobsubmitted_fileOn__DApCu",
	"instruction": "jobsubmitted_instruction__268tS",
	"gridcontainer": "jobsubmitted_gridcontainer__1rZow",
	"reverse": "jobsubmitted_reverse__3mI_-",
	"resubmit": "jobsubmitted_resubmit__22FQn"
};