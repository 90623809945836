exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".contact_contact__1Q2zt{\r\n    width:70vw;\r\n    height:400px;\r\n    margin-top:50px;\r\n    margin-left:50px;\r\n    margin-bottom:50px;\r\n}\r\n\r\n.contact_contact__1Q2zt p{\r\n    margin-left:10px;\r\n    text-align:left;\r\n}\r\n.contact_contact__1Q2zt ul{\r\n    display: block;\r\n    list-style-type: disc;\r\n    -webkit-margin-before: 1em;\r\n            margin-block-start: 1em;\r\n    -webkit-margin-after: 1em;\r\n            margin-block-end: 1em;\r\n    -webkit-margin-start: 1em;\r\n            margin-inline-start: 1em;\r\n    -webkit-margin-end: 1em;\r\n            margin-inline-end: 1em;\r\n    -webkit-padding-start: 40px;\r\n            padding-inline-start: 40px;\r\n}\r\n\r\n.contact_contact__1Q2zt li{\r\n    line-height: 20px;\r\n    color:#333;\r\n    margin-left:20px;\r\n    -webkit-margin-before: 1em;\r\n            margin-block-start: 1em;\r\n    -webkit-margin-after: 1em;\r\n            margin-block-end: 1em;\r\n    -webkit-margin-start: 1em;\r\n            margin-inline-start: 1em;\r\n    -webkit-margin-end: 1em;\r\n            margin-inline-end: 1em;\r\n    -webkit-padding-start: 40px;\r\n            padding-inline-start: 40px;\r\n\r\n}\r\n\r\n", ""]);

// exports
exports.locals = {
	"contact": "contact_contact__1Q2zt"
};